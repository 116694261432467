import React from 'react';

const AssinaturaTiagoManchini = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg
    version='1.0'
    xmlns='http://www.w3.org/2000/svg'
    width='1182.000000pt'
    height='675.000000pt'
    viewBox='0 0 1182.000000 675.000000'
    preserveAspectRatio='xMidYMid meet'
    {...props}
  >
    <g
      transform='translate(0.000000,675.000000) scale(0.100000,-0.100000)'
      fill='#000000'
      stroke='none'
    >
      <path
        d='M9020 6725 c-186 -42 -317 -134 -500 -352 -5 -7 -10 -15 -10 -19 0
-3 -13 -23 -29 -43 -55 -70 -181 -316 -181 -353 0 -9 -6 -21 -14 -27 -8 -6
-16 -24 -19 -39 -3 -15 -16 -58 -30 -97 -13 -38 -32 -97 -41 -130 -9 -33 -21
-73 -26 -90 -9 -29 -36 -163 -59 -295 -15 -81 -27 -182 -41 -325 -6 -60 -16
-162 -22 -225 -9 -81 -13 -442 -14 -1228 l-1 -1114 -63 -191 c-35 -106 -69
-205 -75 -222 -7 -16 -20 -61 -28 -100 -9 -38 -22 -95 -28 -125 -7 -30 -16
-89 -20 -130 -4 -41 -12 -118 -18 -170 -13 -112 -8 -150 25 -181 21 -20 26
-21 54 -9 23 10 38 27 56 66 13 29 24 59 24 66 0 7 7 22 15 32 8 11 15 32 15
46 0 14 4 30 9 35 12 12 30 81 42 165 6 36 14 74 19 85 5 11 11 60 13 108 3
49 10 94 16 100 6 6 11 43 12 82 0 38 5 95 10 125 5 30 14 83 18 118 5 35 14
69 20 75 6 6 11 20 11 32 0 11 7 33 15 49 8 15 15 36 15 46 0 10 7 25 15 34 8
8 15 20 15 26 0 7 7 24 14 38 8 15 22 45 31 67 9 22 23 54 30 70 7 17 16 42
19 58 4 15 11 27 16 27 6 0 10 11 10 24 0 13 7 29 15 36 8 7 15 22 15 34 0 12
7 31 15 42 8 10 15 29 15 41 0 12 7 28 15 37 8 8 15 21 15 30 0 8 6 21 13 28
14 16 43 73 52 106 3 12 11 22 16 22 5 0 9 11 9 24 0 13 7 29 15 36 8 7 15 21
15 32 0 10 7 21 15 24 8 4 15 14 15 25 0 10 6 22 13 26 7 4 24 32 37 61 14 28
28 52 31 52 4 0 10 11 13 25 4 14 11 25 16 25 6 0 10 9 10 20 0 11 7 23 15 26
8 4 15 14 15 23 0 9 14 31 30 49 17 18 30 37 30 43 0 5 5 9 10 9 6 0 15 11 20
25 5 14 14 25 20 25 5 0 10 5 10 11 0 6 19 36 43 67 57 76 77 108 77 126 0 15
59 66 75 66 5 0 17 8 27 17 18 16 20 15 48 -18 61 -72 138 -245 155 -350 10
-65 29 -89 70 -89 18 0 41 7 49 16 17 17 21 64 8 92 -5 9 -16 32 -25 50 -9 19
-17 44 -17 57 0 12 -7 28 -15 35 -8 7 -15 22 -15 34 0 12 -7 31 -15 42 -8 10
-15 29 -15 40 0 12 -7 27 -15 34 -8 7 -15 16 -15 20 0 10 -44 63 -96 114 -75
75 -77 66 35 140 56 36 104 66 109 66 4 0 17 8 28 17 10 10 35 30 55 45 44 32
161 148 212 208 70 84 77 93 77 106 0 7 4 14 8 16 13 5 112 161 112 175 0 7 4
13 10 13 5 0 13 12 16 28 4 15 16 41 26 57 10 17 22 40 27 52 5 11 15 24 20
28 6 3 11 14 11 24 0 10 7 24 15 31 8 7 15 21 15 32 0 11 6 32 13 46 8 15 22
45 31 67 10 22 24 53 32 68 8 16 14 36 14 46 0 9 7 26 15 37 8 10 15 35 15 54
0 19 7 48 15 64 8 15 15 47 15 70 0 22 7 54 15 70 21 40 21 282 0 323 -7 15
-23 48 -35 73 -26 56 -37 74 -75 125 -51 69 -259 285 -275 285 -4 0 -10 4 -12
8 -1 4 -37 37 -79 73 -66 56 -154 109 -224 134 -97 35 -286 45 -395 20z m295
-107 c60 -12 151 -56 224 -108 143 -101 398 -371 439 -463 54 -121 53 -295 -2
-482 -8 -27 -21 -75 -29 -106 -8 -31 -20 -65 -25 -75 -6 -11 -20 -44 -32 -74
-12 -30 -33 -77 -45 -105 -13 -27 -26 -57 -29 -65 -4 -8 -21 -42 -39 -75 -19
-33 -47 -84 -63 -113 -140 -255 -280 -408 -509 -556 -77 -50 -165 -104 -195
-120 -30 -16 -81 -44 -113 -64 -32 -19 -75 -38 -94 -43 -30 -6 -39 -4 -54 14
-10 13 -18 38 -19 60 0 25 -7 44 -22 60 -21 20 -32 22 -121 21 -54 -1 -108 -6
-120 -11 -129 -56 -178 -87 -213 -134 -19 -27 -41 -49 -47 -49 -6 0 -19 -7
-29 -17 -12 -11 -18 -12 -18 -4 0 7 -7 19 -15 27 -18 18 -18 73 -4 279 5 77
13 219 18 316 5 96 14 185 20 197 6 11 11 63 11 114 1 55 7 109 15 133 8 22
15 63 15 90 1 28 7 68 15 90 8 22 15 56 15 75 0 19 7 48 15 64 8 15 15 40 15
55 0 14 7 39 15 55 8 15 15 40 15 55 0 15 7 36 15 47 8 10 15 28 15 40 0 11 7
29 15 40 8 10 15 28 15 39 0 11 7 33 15 49 8 15 15 39 15 51 0 13 7 30 15 39
8 8 15 24 15 36 0 11 7 23 15 26 8 4 15 15 15 25 0 11 7 22 15 25 8 4 15 13
15 21 0 18 26 66 63 114 15 20 27 40 27 44 0 25 224 265 248 265 4 0 17 8 28
18 37 33 163 79 264 96 34 5 134 -2 195 -16z m-785 -2436 c0 -34 45 -75 80
-74 18 0 30 -2 27 -5 -20 -19 -117 -75 -140 -80 -15 -3 -30 -10 -33 -14 -3 -5
-16 -9 -29 -9 -13 0 -27 -4 -30 -10 -3 -5 -17 -10 -30 -10 -13 0 -27 -4 -30
-10 -11 -18 -74 -11 -95 10 l-21 21 20 28 c11 15 36 39 55 52 20 13 36 27 36
30 0 13 80 61 122 74 59 19 68 18 68 -3z m400 -96 c0 -2 -14 -20 -30 -42 -17
-21 -38 -54 -47 -73 -9 -20 -24 -43 -32 -52 -38 -39 -71 -80 -71 -88 0 -6 -6
-14 -13 -18 -11 -7 -63 -80 -77 -108 -3 -5 -18 -29 -33 -53 -15 -23 -37 -59
-48 -80 -11 -20 -29 -50 -39 -67 -32 -52 -137 -254 -168 -325 -11 -25 -39 -85
-61 -133 -23 -49 -41 -94 -41 -101 0 -7 -7 -21 -15 -32 -8 -10 -15 -27 -15
-37 0 -11 -8 -31 -19 -45 -10 -15 -26 -44 -34 -64 -20 -48 -31 -49 -23 -3 5
23 2 45 -10 66 -14 28 -16 85 -18 471 -2 320 -6 442 -14 450 -20 20 -13 53 16
81 25 24 30 25 72 16 25 -6 61 -8 80 -4 90 15 235 65 365 125 44 20 100 45
124 56 25 10 65 29 90 41 46 21 61 26 61 19z'
      />
      <path
        d='M6305 6690 c-16 -4 -48 -8 -69 -9 -22 0 -53 -5 -70 -11 -176 -56
-234 -82 -300 -133 -75 -58 -155 -140 -193 -196 -21 -31 -41 -58 -45 -61 -9
-7 -98 -185 -98 -196 0 -4 -13 -41 -29 -83 -15 -42 -36 -103 -47 -136 -10 -33
-18 -61 -19 -62 -1 -2 -20 6 -43 17 -22 11 -45 20 -51 20 -5 0 -33 23 -62 50
-29 28 -56 50 -60 50 -4 0 -9 3 -11 8 -1 4 -34 30 -73 57 -38 27 -72 51 -75
54 -3 4 -18 13 -35 21 -16 8 -50 28 -75 44 -78 53 -300 172 -410 220 -36 16
-94 42 -130 59 -73 33 -91 40 -140 57 -19 7 -44 16 -55 20 -11 5 -31 11 -45
15 -14 3 -38 10 -55 15 -304 86 -454 110 -824 130 -287 16 -659 -8 -886 -58
-79 -18 -290 -81 -340 -102 -11 -4 -50 -20 -87 -34 -80 -31 -401 -186 -478
-231 -30 -18 -95 -55 -145 -82 -77 -43 -320 -204 -335 -222 -3 -3 -34 -31 -70
-61 -193 -161 -412 -413 -557 -640 -38 -60 -159 -295 -188 -365 -7 -16 -20
-47 -29 -68 -9 -21 -16 -43 -16 -50 0 -7 -6 -23 -13 -37 -34 -67 -107 -385
-107 -473 0 -23 -7 -87 -15 -142 -17 -117 -20 -551 -4 -660 5 -38 14 -118 19
-177 5 -59 11 -112 14 -117 4 -5 11 -47 17 -93 12 -99 50 -273 90 -418 16 -58
37 -130 45 -160 13 -48 34 -108 91 -255 30 -81 138 -292 180 -353 24 -35 43
-65 43 -68 0 -3 5 -10 10 -17 6 -7 49 -61 97 -122 107 -135 408 -438 563 -566
298 -248 578 -427 870 -559 134 -60 381 -140 456 -147 35 -3 64 -9 64 -13 0
-8 32 -13 238 -35 111 -11 166 -13 240 -5 111 12 212 31 212 41 0 3 20 9 44
11 24 3 58 13 76 22 18 9 37 16 42 16 14 0 140 56 241 106 92 47 138 77 220
146 59 50 52 44 142 142 39 43 85 93 103 112 17 19 32 38 32 43 0 4 20 29 45
55 51 54 98 114 108 136 4 8 16 29 27 45 11 17 24 39 30 50 6 11 19 34 30 50
11 17 23 37 27 45 4 8 13 21 20 28 7 7 13 19 13 26 0 8 7 19 15 26 8 7 15 21
15 31 0 11 4 19 9 19 5 0 14 12 20 28 7 15 23 47 36 72 14 25 25 52 25 60 0 9
5 21 11 27 6 6 11 17 13 26 3 20 39 91 49 95 4 2 7 8 7 13 0 10 34 91 50 121
6 10 10 24 10 32 0 8 16 45 35 83 19 37 35 76 35 85 0 9 5 20 10 23 6 3 10 16
10 28 0 12 7 35 15 51 8 15 15 36 15 45 0 10 7 26 15 37 8 10 15 27 15 36 0 9
6 23 13 30 8 7 17 30 21 51 4 20 11 37 16 37 6 0 10 12 10 28 0 15 6 39 14 53
7 14 16 42 20 62 4 21 11 37 16 37 6 0 10 12 10 28 0 15 6 36 14 47 7 11 16
47 19 79 3 32 11 64 16 69 6 6 11 23 11 38 0 15 7 34 15 43 8 8 15 23 15 34 0
11 7 25 15 32 8 7 15 19 15 27 0 7 7 16 15 19 8 4 15 11 15 18 0 6 15 27 33
48 17 20 46 53 62 73 37 46 66 61 131 70 l51 7 7 -47 c5 -32 3 -61 -8 -93 -33
-101 -46 -146 -46 -161 0 -9 -7 -29 -15 -45 -8 -15 -15 -33 -15 -38 0 -5 -9
-29 -20 -53 -13 -28 -18 -54 -14 -70 5 -21 12 -25 43 -25 45 0 51 5 79 60 13
25 32 59 42 75 11 17 22 37 25 45 3 8 13 28 21 43 7 16 14 34 14 41 0 7 11 33
25 57 13 24 29 54 34 66 5 11 15 24 20 28 6 3 11 15 11 25 0 27 37 95 66 122
13 13 24 29 24 36 0 7 7 15 15 18 8 4 15 14 15 23 1 28 65 74 135 97 l68 22
-6 -66 c-9 -94 -90 -328 -135 -392 -27 -37 -32 -58 -22 -85 12 -32 53 -42 85
-21 14 9 25 21 25 27 0 10 54 86 71 99 3 3 18 25 32 50 15 25 38 58 52 74 14
16 25 35 25 41 0 7 7 26 15 41 8 16 15 36 15 44 0 37 73 150 96 150 8 0 27 14
42 31 l27 30 -3 -156 c-2 -87 -1 -173 3 -191 19 -104 220 -95 345 14 8 7 35
26 60 42 l45 29 11 -55 c11 -53 6 -251 -11 -425 -9 -96 -51 -343 -77 -449 -6
-25 -14 -61 -19 -80 -9 -40 -46 -180 -58 -225 -5 -16 -18 -55 -29 -85 -10 -30
-29 -84 -41 -120 -21 -62 -68 -181 -97 -245 -52 -118 -73 -163 -124 -265 -31
-63 -72 -142 -90 -175 -18 -33 -46 -85 -62 -115 -28 -51 -182 -308 -220 -368
-10 -15 -21 -34 -25 -42 -4 -8 -11 -19 -15 -25 -4 -5 -45 -59 -90 -120 -94
-127 -128 -166 -255 -292 -51 -50 -93 -95 -93 -100 0 -22 33 -53 57 -53 47 0
178 117 291 259 20 25 41 51 47 56 17 16 75 105 75 116 0 5 4 9 8 9 13 0 88
106 111 157 5 11 15 24 20 28 6 3 11 15 11 26 0 10 5 19 10 19 6 0 15 10 20
23 12 27 33 60 42 67 4 3 12 17 18 30 5 14 19 39 30 55 11 17 23 37 27 45 4 8
13 21 20 28 7 7 13 22 13 33 0 10 4 19 10 19 5 0 13 12 16 28 4 15 16 41 26
57 10 17 24 42 30 57 7 16 16 28 19 28 4 0 10 14 14 30 4 17 11 30 16 30 5 0
9 7 9 15 0 9 15 44 34 78 34 62 62 117 78 149 4 10 8 26 8 36 0 9 6 26 14 37
7 11 16 32 20 47 3 16 11 28 16 28 6 0 10 10 10 23 0 12 6 31 14 42 7 11 17
35 21 52 3 18 10 33 14 33 5 0 11 19 14 42 3 24 11 44 16 46 6 2 11 15 11 29
0 14 6 37 14 51 8 15 18 44 21 65 3 20 10 37 14 37 5 0 11 22 15 50 4 27 11
52 16 55 6 3 10 25 10 48 0 23 7 55 15 71 8 15 15 46 15 68 0 22 6 54 14 71 8
18 17 63 20 101 3 37 10 73 15 80 12 14 23 257 26 581 3 259 8 313 31 322 8 3
14 10 14 15 0 12 43 60 90 102 62 55 120 120 120 133 0 8 7 16 15 19 8 4 15
11 15 18 0 19 81 106 99 106 9 0 27 9 40 21 12 12 25 18 28 15 6 -5 -5 -101
-27 -249 -5 -32 -14 -64 -20 -72 -20 -24 -17 -85 5 -101 43 -32 74 -11 175
116 19 24 45 55 57 68 28 31 63 97 63 121 0 43 41 110 86 141 43 29 48 30 64
15 54 -48 66 -144 25 -191 -38 -43 -42 -71 -14 -100 31 -34 82 -33 114 2 23
25 25 34 25 140 0 101 -3 118 -25 161 -45 86 -66 101 -142 105 -37 1 -76 -2
-87 -7 -27 -14 -84 -90 -120 -162 -53 -103 -56 -105 -50 -32 4 55 2 70 -18
103 -26 45 -70 74 -112 75 -49 1 -109 -43 -184 -136 -40 -48 -94 -107 -122
-131 l-50 -43 -22 21 c-12 11 -27 20 -32 20 -17 1 -100 81 -124 121 -12 20
-22 45 -22 57 0 11 -7 33 -15 48 -8 16 -15 39 -15 50 0 12 -7 35 -15 50 -8 16
-15 41 -15 55 0 15 -7 32 -15 39 -8 7 -15 22 -15 34 0 11 -7 30 -15 40 -8 11
-15 28 -15 38 0 10 -4 18 -10 18 -5 0 -13 12 -16 28 -4 15 -13 36 -20 47 -8
11 -14 27 -14 37 0 10 -4 18 -9 18 -5 0 -14 12 -20 28 -6 15 -16 36 -23 47 -7
11 -25 39 -40 63 -16 24 -28 47 -28 53 0 9 -28 58 -40 69 -3 3 -23 28 -45 55
-22 28 -43 52 -47 53 -15 7 -8 59 10 74 9 8 44 28 77 43 33 16 79 41 102 57
23 15 45 28 48 28 7 0 131 85 215 148 74 55 141 114 194 171 28 30 59 62 69
73 9 10 17 20 17 23 0 9 44 70 53 73 4 2 7 14 7 27 0 12 7 28 15 35 8 7 15 20
15 30 0 10 7 23 15 30 8 7 15 22 15 34 0 12 7 31 15 42 8 10 15 35 15 54 0 19
7 46 15 60 21 36 20 297 0 324 -8 11 -15 36 -15 57 0 20 -7 42 -15 49 -8 7
-15 20 -15 30 0 10 -7 23 -15 30 -8 7 -15 17 -15 22 0 35 -184 218 -219 218
-5 0 -11 3 -13 8 -5 11 -76 54 -133 81 -27 13 -58 27 -67 32 -10 5 -26 9 -36
9 -10 0 -22 4 -27 9 -20 19 -268 35 -320 21z m219 -115 c77 -9 182 -48 276
-105 95 -57 219 -178 250 -245 101 -220 52 -521 -125 -760 -16 -22 -55 -66
-87 -97 -87 -86 -344 -276 -395 -292 -12 -4 -45 -23 -72 -42 -71 -48 -124 -68
-165 -60 -37 7 -54 23 -83 76 -17 33 -46 70 -85 112 -10 10 -18 21 -18 24 0 7
-45 55 -180 193 -52 54 -115 120 -140 147 -25 28 -63 64 -85 80 -76 56 -75 54
-75 119 1 37 6 67 15 79 8 11 15 36 15 57 0 20 7 42 15 49 8 7 15 24 15 39 0
14 7 35 15 45 8 11 15 30 15 42 0 12 7 27 15 34 8 7 15 20 15 30 0 10 7 23 15
30 8 7 15 21 15 31 0 11 5 19 10 19 6 0 13 8 17 18 3 9 19 33 35 53 15 20 28
42 28 48 0 6 5 11 10 11 6 0 14 8 17 17 23 59 182 171 298 209 73 24 295 55
340 48 11 -2 47 -6 79 -9z m-3126 -51 c94 -4 172 -11 172 -15 0 -5 11 -9 26
-9 67 0 374 -62 514 -105 309 -94 655 -265 932 -462 167 -119 335 -252 350
-277 6 -11 -1 -68 -27 -191 -39 -191 -77 -461 -114 -815 -9 -85 -13 -97 -37
-116 -25 -21 -127 -75 -348 -187 -54 -27 -145 -78 -203 -113 -58 -35 -109 -64
-114 -64 -5 0 -9 -4 -9 -10 0 -5 -6 -10 -12 -10 -19 -1 -165 -111 -185 -140
-9 -14 -20 -27 -23 -30 -25 -18 -45 -91 -45 -160 1 -66 5 -83 35 -138 46 -86
98 -129 253 -208 179 -91 239 -111 377 -126 l118 -12 -5 -41 c-3 -22 -11 -59
-19 -83 -8 -23 -14 -57 -14 -75 -1 -18 -7 -43 -15 -57 -8 -14 -14 -37 -15 -52
0 -15 -7 -44 -16 -65 -9 -21 -22 -51 -29 -68 -7 -16 -20 -47 -29 -68 -9 -21
-16 -52 -16 -70 0 -56 -121 -415 -196 -584 -8 -17 -14 -36 -14 -41 0 -6 -6
-23 -14 -39 -8 -15 -36 -80 -62 -143 -91 -224 -282 -606 -330 -661 -8 -8 -14
-19 -14 -23 0 -5 -13 -25 -30 -46 -16 -21 -30 -43 -30 -49 0 -6 -4 -11 -9 -11
-4 0 -20 -17 -34 -38 -14 -20 -37 -50 -51 -67 -14 -16 -43 -50 -64 -75 -66
-80 -235 -245 -300 -293 -35 -26 -130 -79 -210 -118 -162 -79 -169 -82 -227
-97 -41 -11 -51 -13 -185 -34 -136 -20 -359 -12 -545 22 -33 6 -77 13 -98 16
-20 4 -40 10 -43 15 -3 5 -15 9 -26 9 -20 0 -65 12 -113 30 -11 5 -36 14 -55
21 -19 7 -68 28 -108 46 -39 18 -77 33 -82 33 -6 0 -10 5 -10 10 0 6 -5 10
-10 10 -12 0 -103 41 -110 50 -3 3 -21 12 -40 20 -19 8 -37 16 -40 20 -3 3
-30 18 -60 34 -104 55 -446 298 -500 356 -8 9 -28 26 -45 38 -116 87 -427 395
-500 495 -27 37 -52 67 -57 67 -4 0 -8 7 -8 15 0 8 -4 15 -8 15 -9 0 -75 88
-85 113 -3 9 -11 17 -17 17 -5 0 -10 9 -10 20 0 11 -7 23 -15 26 -8 4 -15 14
-15 24 0 10 -7 20 -15 24 -8 3 -15 12 -15 19 0 8 -7 20 -15 27 -8 7 -15 23
-15 36 0 13 -4 24 -10 24 -5 0 -14 18 -20 40 -6 22 -15 40 -20 40 -6 0 -10 7
-10 16 0 9 -6 28 -14 42 -28 54 -46 101 -46 124 0 12 -7 32 -15 42 -8 11 -15
31 -15 45 -1 14 -7 37 -15 51 -8 14 -15 39 -15 55 0 17 -7 39 -15 49 -8 11
-15 34 -15 52 0 17 -7 45 -15 60 -8 16 -15 50 -15 75 0 26 -7 60 -15 75 -8 16
-15 54 -15 85 0 31 -7 70 -15 85 -8 16 -15 47 -15 69 0 23 -5 46 -11 52 -6 6
-14 57 -19 114 -5 57 -14 124 -19 149 -13 58 -13 498 0 570 6 30 14 107 18
170 4 63 12 124 18 135 5 11 13 44 16 74 4 30 11 59 17 65 5 5 10 31 10 57 0
29 6 53 15 63 8 8 15 28 15 44 0 16 7 35 15 42 8 7 15 27 15 46 0 18 7 39 15
48 8 8 15 28 15 44 0 16 7 35 15 42 8 7 15 23 15 37 0 13 7 26 15 29 8 4 15
14 15 24 0 10 7 23 15 30 8 7 15 20 15 30 0 10 7 23 15 30 8 7 15 21 15 32 0
10 7 21 15 24 8 4 15 14 15 24 0 10 7 20 15 24 8 3 15 12 15 21 0 8 4 15 9 15
5 0 14 12 20 28 13 33 25 51 79 124 23 31 42 61 42 67 0 6 5 11 10 11 6 0 13
7 17 16 3 9 26 36 50 60 23 24 43 45 43 48 0 2 58 62 128 132 143 145 223 211
362 304 52 34 107 71 122 81 15 11 30 19 32 19 3 0 35 18 71 40 36 22 70 40
75 40 6 0 10 5 10 10 0 6 8 10 18 10 10 0 22 4 27 9 12 10 287 150 330 168 17
7 64 26 105 43 86 36 254 92 320 108 83 19 312 63 360 69 88 10 344 21 430 17
52 -2 173 -7 268 -10z m2205 -1055 c80 -67 487 -529 487 -553 0 -19 -62 -54
-140 -79 -41 -14 -88 -32 -105 -40 -40 -20 -71 -32 -125 -48 -25 -7 -49 -15
-55 -19 -5 -3 -28 -12 -50 -19 -22 -7 -49 -16 -60 -21 -44 -19 -154 -60 -161
-60 -33 0 -44 90 -19 160 8 23 15 77 16 119 0 42 4 99 10 126 5 28 13 76 18
108 5 32 14 64 19 70 6 7 12 65 14 130 3 108 5 120 25 137 12 9 25 30 29 44
l7 27 31 -29 c17 -16 44 -40 59 -53z m666 -794 c159 -243 278 -496 350 -740
38 -131 42 -114 -46 -202 -45 -44 -96 -108 -119 -146 -32 -57 -40 -65 -42 -45
-5 34 -70 97 -108 104 -43 8 -74 -12 -149 -96 -46 -51 -61 -62 -63 -49 -2 10
-24 37 -49 59 -61 54 -96 54 -194 -1 -124 -68 -193 -126 -246 -208 -27 -42
-49 -68 -51 -59 -4 19 -117 78 -150 78 -47 0 -129 -31 -180 -68 -18 -13 -35
-22 -37 -20 -3 2 0 20 6 39 6 19 14 82 18 141 5 59 13 113 18 120 6 7 13 50
15 96 3 45 10 88 16 94 7 7 12 39 12 73 1 33 6 80 11 105 6 25 15 90 20 145 5
55 13 113 19 129 5 16 10 70 10 120 0 122 6 129 180 203 64 28 80 36 80 45 0
4 11 8 25 8 14 0 44 8 68 18 53 23 91 37 197 71 47 15 108 38 135 51 58 27
145 59 161 60 6 0 47 -56 93 -125z m-1049 -297 c1 -103 -88 -729 -121 -864 -9
-33 -17 -43 -43 -52 -39 -14 -67 -15 -115 -3 -20 6 -70 17 -111 27 -60 13
-218 82 -240 104 -3 3 -31 19 -63 35 -105 54 -147 106 -147 184 0 126 78 191
505 416 50 26 118 65 152 86 35 22 86 47 115 58 67 23 68 23 68 9z m1480 -807
c11 -51 18 -103 14 -114 -3 -11 -49 -62 -100 -114 -102 -102 -148 -128 -172
-96 -14 19 -8 76 8 81 6 2 10 11 10 21 0 9 7 26 15 37 8 10 15 29 15 41 0 12
6 28 13 35 8 7 17 26 21 41 3 15 11 27 16 27 6 0 10 6 10 13 0 19 117 133 127
123 1 -1 11 -44 23 -95z'
      />
      <path
        d='M11573 5886 c-86 -48 -113 -71 -113 -96 0 -14 5 -31 12 -38 14 -14
88 -16 88 -2 0 6 9 10 21 10 11 0 34 8 50 17 28 17 29 17 62 -15 40 -39 69
-41 92 -6 16 25 16 28 -2 61 -34 63 -66 83 -130 82 -32 -1 -68 -6 -80 -13z'
      />
      <path
        d='M10703 5583 c-35 -7 -73 -52 -73 -87 0 -42 16 -56 62 -56 31 0 45 8
83 46 25 25 45 55 45 65 0 32 -47 46 -117 32z'
      />
      <path
        d='M11706 4935 c-8 -8 -30 -42 -48 -75 -18 -33 -36 -60 -40 -60 -5 0 -8
-6 -8 -13 0 -7 -12 -26 -27 -43 -16 -16 -44 -50 -63 -74 -43 -57 -216 -230
-229 -230 -13 0 -26 119 -23 212 2 70 0 78 -19 88 -32 18 -82 4 -116 -33 -17
-17 -58 -70 -92 -117 -34 -47 -65 -89 -69 -95 -4 -5 -28 -39 -54 -75 -47 -67
-114 -137 -106 -110 3 8 -1 27 -8 42 -12 24 -12 33 2 68 16 39 15 43 -2 75
-51 96 -110 62 -209 -120 -55 -100 -161 -253 -177 -254 -5 -1 -8 8 -8 19 0 12
-7 32 -16 46 -14 21 -15 31 -5 63 24 82 5 131 -53 131 -18 0 -30 -7 -36 -22
-6 -13 -14 -30 -19 -38 -5 -8 -18 -35 -30 -60 -12 -25 -31 -58 -41 -75 -11
-16 -26 -44 -34 -61 -20 -46 -175 -199 -213 -212 l-33 -11 0 54 c0 30 5 57 10
60 6 3 10 25 10 48 0 24 7 52 15 64 8 12 18 48 21 81 6 57 5 61 -20 77 -24 16
-28 16 -51 1 -13 -9 -36 -40 -50 -69 -14 -28 -34 -65 -44 -82 -18 -31 -74
-154 -107 -235 -27 -66 -82 -158 -120 -200 l-33 -37 -63 9 c-46 6 -64 5 -70
-4 -12 -18 -3 -84 16 -113 56 -86 131 -65 239 66 20 25 37 49 37 53 0 4 5 12
10 19 35 41 70 91 70 99 0 5 13 2 30 -7 36 -19 83 -19 142 -1 75 22 187 107
188 143 0 14 43 63 56 63 4 0 18 10 31 22 l23 21 0 -20 c0 -12 12 -35 26 -52
46 -55 111 -38 191 49 18 19 33 42 33 51 0 8 19 31 41 50 23 19 55 54 70 79
l29 45 -6 -42 c-12 -82 43 -121 119 -84 73 36 227 198 227 240 0 12 24 44 57
77 32 31 67 69 78 84 l20 28 0 -129 c0 -117 2 -130 20 -143 30 -23 56 -19 111
15 52 34 204 182 294 289 30 35 61 71 68 79 30 32 90 118 96 139 4 12 12 22
17 22 5 0 9 8 9 19 0 10 7 24 15 31 18 15 20 61 3 78 -17 17 -64 15 -82 -3z'
      />
    </g>
  </svg>
);

AssinaturaTiagoManchini.displayName = 'AssinaturaTiagoManchini';
export default AssinaturaTiagoManchini;
