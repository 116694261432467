// eslint-disable-next-line filenames-simple/naming-convention
import React from 'react';

function LinkedinIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M4 5.146C4 4.513 4.526 4 5.175 4H18.825C19.474 4 20 4.513 20 5.146V18.854C20 19.487 19.474 20 18.825 20H5.175C4.526 20 4 19.487 4 18.854V5.146ZM8.943 17.394V10.169H6.542V17.394H8.943ZM7.743 9.182C8.58 9.182 9.101 8.628 9.101 7.934C9.086 7.225 8.581 6.686 7.759 6.686C6.937 6.686 6.4 7.226 6.4 7.934C6.4 8.628 6.921 9.182 7.727 9.182H7.743ZM12.651 17.394V13.359C12.651 13.143 12.667 12.927 12.731 12.773C12.904 12.342 13.299 11.895 13.963 11.895C14.832 11.895 15.179 12.557 15.179 13.529V17.394H17.58V13.25C17.58 11.03 16.396 9.998 14.816 9.998C13.542 9.998 12.971 10.698 12.651 11.191V11.216H12.635C12.6403 11.2077 12.6456 11.1993 12.651 11.191V10.169H10.251C10.281 10.847 10.251 17.394 10.251 17.394H12.651Z'
        fill='white'
      />
    </svg>
  );
}

LinkedinIcon.displayName = 'LinkedinIcon';

export default LinkedinIcon;
