import React from 'react';
import { Link } from 'gatsby';

import './show-certification-bread-crumb.css';

function CertificationBreadCrumb(): JSX.Element {
  return (
    <nav className='container-bread-crumb-certification'>
      <div className='module-theme-path'>
        <Link className='path-bread-crumb' to={`/settings`}>
          Configurações
        </Link>
        <p className='path-bread-crumb-next'>&nbsp;{' > '}&nbsp;</p>
        <p className='path-bread-crumb-next'>Atestado de conclusão</p>
      </div>
    </nav>
  );
}

CertificationBreadCrumb.displayName = 'CertificationBreadCrumb';

export default CertificationBreadCrumb;
