import React from 'react';

function XIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='24.000000pt'
      height='24.000000pt'
      viewBox='0 0 24.000000 24.000000'
      preserveAspectRatio='xMidYMid meet'
      {...props}
    >
      <g
        transform='translate(0.000000,24.000000) scale(0.100000,-0.100000)'
        fill='#000000'
        stroke='none'
      >
        <path
          d='M10 234 c0 -3 18 -32 40 -64 22 -32 40 -61 40 -64 0 -3 -18 -26 -40
-52 -54 -62 -43 -70 13 -9 l43 48 30 -46 c25 -40 34 -47 62 -47 17 0 32 3 32
6 0 3 -20 34 -44 69 l-44 63 45 51 c24 28 40 51 34 51 -6 0 -27 -18 -47 -40
-20 -22 -39 -40 -43 -40 -3 0 -17 18 -31 40 -21 33 -30 40 -57 40 -18 0 -33
-2 -33 -6z m118 -103 c67 -96 80 -121 64 -121 -9 0 -152 193 -152 205 0 3 6 5
13 5 8 0 41 -40 75 -89z'
        />
      </g>
    </svg>
  );
}

XIcon.displayName = 'XIcon';

export default XIcon;
