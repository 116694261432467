import { jsPDF, jsPDFOptions } from 'jspdf';
import html2canvas from 'html2canvas';

export const generatePDF = (element: HTMLElement, filename: string): void => {
  const options: jsPDFOptions = {
    orientation: 'landscape',
    unit: 'mm'
  };

  void html2canvas(element, { scale: 3, windowWidth: 3000 }).then(canvas => {
    const imgData = canvas.toDataURL('image/png');

    const pdf = new jsPDF(options);
    const imgWidth = 298;
    const imgHeight = (canvas.height * imgWidth) / canvas.width + 0.5; // Maintain aspect ratio

    pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');
    pdf.save(`${filename}.pdf`);
  });
};
